<template>
    <div class="view pa24">
        <div class="d-flex">
            <div style="margin-left: auto">
                <el-button class="mr10" type="primary" @click="showAddModel">发布动态</el-button>
                <el-button type="primary" @click="addArticlesList">添加推送</el-button>
            </div>
        </div>
        <div>
            <el-tabs class="tabs" v-model="tag" @tab-click="handleClick">
                <el-tab-pane label="全部" name="all"></el-tab-pane>
                <el-tab-pane :label="row.title" :name="String(row.typeId)" v-for="(row, index) in tagData"
                    :key="index"></el-tab-pane>
            </el-tabs>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total"
            @handleSelectionChange="handleSelectionChange">
            <template v-slot:table>
                <!-- <el-table-column type="index" align="center" label="序号" /> -->
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="photos" align="center" label="封面图" width="120px" required>
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.photos" style="width: 100px; height: 100px;margin-bottom:-10px"
                            :src="scope.row.photos">
                        </el-image>
                        <p v-else
                            style="width: 100px; height: 100px;line-height: 100px;font-size:12px;margin:0 auto;background: #eee;">
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="title" align="center" label="标题" show-overflow-tooltip />
                <el-table-column prop="typeName" align="center" label="类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ contentType[scope.row.contentType] }}
                    </template>
                </el-table-column>
                <el-table-column prop="typeName" align="center" label="动态类型" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="130">
                    <template slot-scope="scope">
                        <el-button class="mr10" type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                            @confirm="delMessage(scope.row)" icon="el-icon-info" icon-color="red" title="确定要删除这条社团动态？">
                            <el-button slot="reference" style="color: #F56C6C" type="text">删除</el-button>
                        </customPopconfirm>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" @close="closeFun" :destroy-on-close="true" width="50%" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
                <el-row>
                    <el-col :md="11">
                        <el-form-item label="标题" prop="title">
                            <el-input v-model="ruleForm.title"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="mr20">
                        <el-form-item label="作者" prop="author">
                            <el-input v-model="ruleForm.author"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="来源" prop="originAuthor">
                            <el-input v-model="ruleForm.originAuthor"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="11" class="mr20">
                        <el-form-item label="类型" prop="contentType">
                            <el-select class="w100p" v-model="ruleForm.contentType" @change="changeContentType"
                                placeholder="请选择类型">
                                <el-option v-for="(name, id) in contentType" :key="id" :label="name"
                                    :value="Number(id)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="11">
                        <el-form-item label="动态类型" prop="typeId">
                            <el-select class="w100p" v-model="ruleForm.typeId"
                                :disabled="ruleForm.contentType || ruleForm.contentType === 0 ? false : true"
                                placeholder="请选择动态类型">
                                <el-option v-for="(row, index) in typeData" :key="index" :disabled="row.disabled"
                                    :label="row.title" :value="Number(row.typeId)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-form-item label="描述" prop="content">
                    <el-input type="textarea" :rows="3" v-model="ruleForm.remark"></el-input>
                </el-form-item>
                <el-form-item label="动态介绍" prop="content">
                    <quill-editor style="height: 100%;" v-model="ruleForm.content" ref="myQuillEditor"
                        :options="editorOption" />
                </el-form-item>
                <el-form-item label="封面图" prop="typeIcon">
                    <div class="mb10">
                        <el-radio-group v-model="ruleForm.isFirst" size="medium">
                            <el-radio-button :label="0">手动上传</el-radio-button>
                            <el-radio-button :label="1">默认正文第一张</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div v-if="ruleForm.isFirst === 0">
                        <el-upload :action="$store.state.uploadingUrl" list-type="picture-card"
                            :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress"
                            :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="photos">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <div class="d-flex w100p align-items-center">
                            <p>最多只能上传1张，建议上传150x150，格式可为png、jpg或jpeg</p>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitForm" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写动态介绍'
var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);

import { getAssociationMessageList, setAssociationMessage, getContentTypeList, delAssociationMessage, getByDictType } from "@/api/association"
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
export default {
    name: "associationDynamic",
    components: {
        commonTable,
        quillEditor,
        customPopconfirm
    },
    data() {
        return {
            //新增/修改配置
            editorOption: quillConfig,
            disabled: false,
            initImg: "https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/photo/20221109/a7d99a72a8a5e1600843519277672f64.jpg",
            ruleForm: {
                contentType: "",
                photos: "",
                title: "",
                typeId: "",
                associationId: "",
                content: "",
                remark: "",
                originAuthor: "",
                author: "",
                isFirst: 0,
            },
            contentType: {},//类型数据
            photos: [],//文件数据
            rules: {//提交验证
                title: [{ required: true, message: "请输入标题", trigger: "blur" }],
                contentType: [{ required: true, message: "请选择类型", trigger: "change" }],
                typeId: [{ required: true, message: "请选择动态类型", trigger: "change" }],
            },
            modelTitle: "",//模态框标题
            formType: "",//提交类型
            updateId: 0,//修改id
            typeDataAll: {},//动态类型数据
            typeData: [],//筛选过的动态类型数据
            tagData: [],
            //列表配置
            tag: "all",
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            total: 0, //总条数
            loading: false, //表格加载
            showModel: false,//模态框显示状态
            tableData: [],//列表数据
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            associationId: 0,
            loadingBtn: false,
            sendArticleData: [],
        };
    },
    computed: {
        uploadDisabled() {
            return this.ruleForm.photos !== "";
        },
        watchAssociation() {
            return this.$store.state.checkedAssociation
        },
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            this.currentPage = 1;
            this.getContentTypeList().then(() => {
                this.getList();
            });
        },
        'ruleForm.content'() {
           //-console.log(this.ruleForm.content);
        },
    },
    filters: {

    },
    created() {
        let checkedAssociation = sessionStorage.getItem('checkedAssociation');
        this.associationId = JSON.parse(checkedAssociation).associationId;
        this.loading = true;
        this.getContentTypeList().then(() => {
            this.getList();
        });
        this.getByDictType();
    },
    methods: {
        /**@method 添加推送数据 */
        addArticlesList() {
            if (this.sendArticleData.length > 0) {
                let dataLength = this.$store.state.sendArticleData.length + this.sendArticleData.length;
                if (dataLength > 8) {
                    this.$message({
                        type: "info",
                        message: "推送的数据不能大于8条"
                    })
                    return;
                }
                this.$store.commit("addSendArticleData", this.sendArticleData)
                this.$message({
                    type: "success",
                    message: "添加成功，重复的数据会合并"
                })
            } else {
                this.$message({
                    type: "info",
                    message: "请选择要推送的文章"
                })
            }
        },
        /**@method 筛选动态类型 */
        changeContentType(contentType) {
            this.typeData = this.typeDataAll[contentType] ? this.typeDataAll[contentType] : [];
        },
        closeFun() {
            this.ruleForm.photos = "";
            this.photos = [];
        },
        /**@method 删除图片 */
        linkmanImgRemove(file, fileList) {
            //-console.log(file, fileList);
            this.ruleForm.photos = "";
            this.photos = [];
        },
        /**@method 图片上传 */
        linkmanImgProgress(event, file, fileList) {

        },
        /**@method 上传回调 */
        linkmanImgSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.ruleForm.photos = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
        },
        /**@method 删除社团动态 */
        delMessage(row) {
            delAssociationMessage({ messageId: row.messageId }).then(res => {
                if (res.code === 200) {
                    this.getList();
                } else {
                    this.$message.error(err.message);
                }
            }).catch(err => {
                if (err.code) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 获取动态分类 */
        async getContentTypeList() {
            let res = await getContentTypeList({ associationId: this.associationId, status: 0 })
            if (res.code === 200) {
                let tagData = [];
                let typeDataAll = {}
                for (let row of res.data) {
                    tagData.push({ title: row.typeName, dictValue: row.dictValue, typeId: row.typeId });
                    if (typeDataAll[row.dictValue] && typeDataAll[row.dictValue].length > 0) {
                        typeDataAll[row.dictValue].push({ title: row.typeName, dictValue: row.dictValue, typeId: row.typeId });
                    } else {
                        typeDataAll[row.dictValue] = [{ title: row.typeName, dictValue: row.dictValue, typeId: row.typeId }];
                    }
                }
                this.tagData = tagData;
                this.typeDataAll = typeDataAll;
            }
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "发布动态";
            this.formType = "add";
            this.ruleForm = {
                photos: this.initImg,
                isFirst: 0,
                title: "",
                typeId: "",
                associationId: this.associationId,
                content: "",
                remark: "",
                originAuthor: "",
                author: "",
            };
            this.updateId = 0;
            this.photos = [{ url: this.initImg }]
        },
        /**@method 获取字典数据 */
        getByDictType() {
            return getByDictType({ dictType: "content_type", status: 0 }).then(res => {
                if (res.code === 200) {
                    let contentType = {};
                    for (let row of res.data.sysDictDataList) {
                        contentType[row.dictValue] = row.dictLabel;
                    }
                    this.contentType = contentType;
                }
            })
        },
        /**@method 显示编辑模态框
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.modelTitle = "编辑动态";
            this.formType = "update";
            this.updateId = row.messageId;
            this.ruleForm = {
                contentType: Number(row.contentType),
                photos: row.photos ? row.photos : "",
                title: row.title,
                typeId: row.typeId,
                associationId: this.associationId,
                content: row.content,
                remark: row.remark,
                isFirst: row.isFirst,
                originAuthor: row.originAuthor,
                author: row.author,
            };
            this.changeContentType(row.contentType)
            if (row.photos) {
                this.photos = [{ url: row.photos }]
            }
        },
        /**提交内容 */
        submitForm(payload) {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.ruleForm,
                        associationId: this.associationId
                    };
                    let message = "";
                    if (this.formType === 'update') {
                        message = "编辑成功"
                        params.messageId = this.updateId;
                    } else {
                        message = "新增成功"
                    }
                    this.loadingBtn = true;
                    setAssociationMessage(params).then(res => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            this.$message({
                                message: message,
                                type: "success"
                            })
                            this.showModel = false;
                            this.getList();
                        } else {
                            this.$message.error(err.message)
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                } else {
                    //-console.log("error submit!!");
                    return false;
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            this.sendArticleData = [];
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                associationId: this.associationId,
                isPage: 1
            };
            if (this.tag !== "all") {
                params.typeId = this.tag;
            }
            try {
                this.loading = true;
                let res = await getAssociationMessageList(params);
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 动态类型筛选 */
        handleClick() {
            this.currentPage = 1;
            this.getList();
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        handleSelectionChange(data) {
            let sendArticleData = [];
            for (let row of data) {
                sendArticleData.push({
                    title: row.title,
                    id: row.messageId,
                    optionType: 2,
                    img: row.photos
                });
            }
            this.sendArticleData = sendArticleData;
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>
<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>